/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
// export const countDot = (currency: string) => {
//   let count = 0;
//   // eslint-disable-next-line no-plusplus
//   for (let i = 0; i < currency.length; i++) {
//     if (currency[i] === '.') {
//       // eslint-disable-next-line no-plusplus
//       count++;
//     }
//   }
//   const totalLength = currency.length - count - 1;
//   return {
//     dot: count || 0,
//     char: totalLength > 0 ? totalLength : 0,
//   };
// };

export const calculateSize = (displayPriceFormated: string, size: 'xs' | 'sm' | 'smn' | 'md' | 'mdx' | 'mdn' | 'lg') => {
  switch (size) {
    case 'xs': {
      const fontSize = 40;
      const lineHeight = 44;
      let totalWidth = 0;

      let x = 0;
      const symbols = [];
      for (let i = 0; i < displayPriceFormated.length; i++) {
        const char = displayPriceFormated[i];

        let charCellWidth = 24; // Default cellWidth
        if (char === ' ') {
          charCellWidth = 1;
        } else if (char === '.') {
          charCellWidth = 10;
        } else if (char === 'đ') {
          charCellWidth = 24;
        } else {
          switch (char) {
            case '1': {
              charCellWidth = 16;
              break;
            }
            case '2': {
              charCellWidth = 20;
              break;
            }
            default: {
              charCellWidth = 25;
              break;
            }
          }
        }
        totalWidth += charCellWidth;
        // Calculate x position for the character
        const charX = x;
        symbols.push({
          char,
          charX,
        });

        x += charCellWidth;
      }
      return {
        fontSize,
        totalWidth,
        symbols,
        lineHeight,
        height: 60,
        style: {
          height: 18,
        },
      };
    }
    case 'sm': {
      const fontSize = 40;
      const lineHeight = 44;
      let totalWidth = 0;

      let x = 0;
      const symbols = [];
      for (let i = 0; i < displayPriceFormated.length; i++) {
        const char = displayPriceFormated[i];

        let charCellWidth = 24; // Default cellWidth
        if (char === ' ') {
          charCellWidth = 1;
        } else if (char === '.') {
          charCellWidth = 10;
        } else if (char === 'đ') {
          charCellWidth = 24;
        } else {
          switch (char) {
            case '1': {
              charCellWidth = 16;
              break;
            }
            case '2': {
              charCellWidth = 23;
              break;
            }
            case '7': {
              charCellWidth = 23;
              break;
            }
            default: {
              charCellWidth = 25;
              break;
            }
          }
        }
        totalWidth += charCellWidth;
        // Calculate x position for the character
        const charX = x;
        symbols.push({
          char,
          charX,
        });

        x += charCellWidth;
      }
      return {
        fontSize,
        totalWidth,
        symbols,
        lineHeight,
        height: 60,
        style: {
          height: 21,
        },
      };
    }
    case 'smn': {
      const fontSize = 45;
      const lineHeight = 46;
      let totalWidth = 0;

      let x = 0;
      const symbols = [];
      for (let i = 0; i < displayPriceFormated.length; i++) {
        const char = displayPriceFormated[i];

        let charCellWidth = 25; // Default cellWidth
        if (char === ' ') {
          charCellWidth = 1;
        } else if (char === '.') {
          charCellWidth = 15;
        } else if (char === 'đ') {
          charCellWidth = 35;
        } else {
          switch (char) {
            case '1': {
              charCellWidth = 23;
              break;
            }
            case '2': {
              charCellWidth = 20;
              break;
            }
            default: {
              charCellWidth = 30;
              break;
            }
          }
        }
        totalWidth += charCellWidth;
        // Calculate x position for the character
        const charX = x;
        symbols.push({
          char,
          charX,
        });

        x += charCellWidth;
      }
      return {
        fontSize,
        totalWidth,
        symbols,
        lineHeight,
        height: 60,
        style: {
          height: 15,
        },
      };
    }
    case 'md': {
      const fontSize = 40;
      const lineHeight = 48;
      let totalWidth = 0;

      let x = 0;
      const symbols = [];
      for (let i = 0; i < displayPriceFormated.length; i++) {
        const char = displayPriceFormated[i];

        let charCellWidth = 24; // Default cellWidth
        if (char === ' ') {
          charCellWidth = 1;
        } else if (char === '.') {
          charCellWidth = 10;
        } else if (char === 'đ') {
          charCellWidth = 24;
        } else {
          switch (char) {
            case '1': {
              charCellWidth = 16;
              break;
            }
            case '2': {
              charCellWidth = 24;
              break;
            }
            case '7': {
              charCellWidth = 23;
              break;
            }
            default: {
              charCellWidth = 25;
              break;
            }
          }
        }
        totalWidth += charCellWidth;
        // Calculate x position for the character
        const charX = x;
        symbols.push({
          char,
          charX,
        });

        x += charCellWidth;
      }
      return {
        fontSize,
        totalWidth,
        symbols,
        lineHeight,
        height: 66,
        style: {
          height: 25,
        },
      };
    }
    case 'mdx': {
      const fontSize = 46;
      const lineHeight = 52;
      let totalWidth = 0;

      let x = 0;
      const symbols = [];
      for (let i = 0; i < displayPriceFormated.length; i++) {
        const char = displayPriceFormated[i];

        let charCellWidth = 28; // Default cellWidth
        if (char === ' ') {
          charCellWidth = 1;
        } else if (char === '.') {
          charCellWidth = 14;
        } else if (char === 'đ') {
          charCellWidth = 28;
        } else {
          switch (char) {
            case '1': {
              charCellWidth = 22;
              break;
            }
            case '2': {
              charCellWidth = 26;
              break;
            }
            default: {
              charCellWidth = 30;
              break;
            }
          }
        }
        totalWidth += charCellWidth;
        // Calculate x position for the character
        const charX = x;
        symbols.push({
          char,
          charX,
        });

        x += charCellWidth;
      }
      return {
        fontSize,
        totalWidth,
        symbols,
        lineHeight,
        height: 66,
        style: {
          height: 25,
        },
      };
    }
    case 'mdn': {
      const fontSize = 50;
      const lineHeight = 51;
      let totalWidth = 0;

      let x = 0;
      const symbols = [];
      for (let i = 0; i < displayPriceFormated.length; i++) {
        const char = displayPriceFormated[i];

        let charCellWidth = 18; // Default cellWidth
        if (char === ' ') {
          charCellWidth = 1;
        } else if (char === '.') {
          charCellWidth = 14;
        } else if (char === 'đ') {
          charCellWidth = 16;
        } else {
          switch (char) {
            case '1': {
              charCellWidth = 23;
              break;
            }
            case '2': {
              charCellWidth = 24;
              break;
            }
            case '9': {
              charCellWidth = 29;
              break;
            }
            default: {
              charCellWidth = 34;
              break;
            }
          }
        }
        totalWidth += charCellWidth;
        // Calculate x position for the character
        const charX = x;
        symbols.push({
          char,
          charX,
        });

        x += charCellWidth;
      }
      return {
        fontSize,
        totalWidth,
        symbols,
        lineHeight,
        height: 66,
        style: {
          height: 25,
        },
      };
    }
    case 'lg': {
      const fontSize = 60;
      const lineHeight = 68;
      let totalWidth = 0;

      let x = 0;
      const symbols = [];
      for (let i = 0; i < displayPriceFormated.length; i++) {
        const char = displayPriceFormated[i];

        let charCellWidth = 23; // Default cellWidth
        if (char === ' ') {
          charCellWidth = 1;
        } else if (char === '.') {
          charCellWidth = 17;
        } else if (char === 'đ') {
          charCellWidth = 40;
        } else {
          switch (char) {
            case '1': {
              charCellWidth = 28;
              break;
            }
            case '2': {
              charCellWidth = 33;
              break;
            }
            default: {
              charCellWidth = 36;
              break;
            }
          }
        }
        totalWidth += charCellWidth;
        // Calculate x position for the character
        const charX = x;
        symbols.push({
          char,
          charX,
        });

        x += charCellWidth;
      }
      return {
        fontSize,
        totalWidth,
        symbols,
        lineHeight,
        height: 80,
        style: {
          height: 30,
        },
      };
    }
  }
};
