import { POST, PUT, getFirst, isValid, isValidWithoutData } from 'clients/Clients';
import ProductClientV2 from 'clients/ProductClientV2';
import { CART_API } from 'constants/APIUriV2';
import { convertCartStore } from 'services/ProductServiceV3';
import { CartStore, IOldCartItem, IRedeemApplyResult } from 'types/product';
import Insider, { INSIDER_CART_SOURCE } from 'utils/Insider';
import { mapScreenToEnum, mapSourceToEnum } from 'utils/MonitorUtils';
import NotifyUtils from 'utils/NotifyUtils';
import { capitalizeText } from 'utils/StringUtils';
import gtag from 'utils/gtag';
import create from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  CartParams,
  FETCH_STATUS,
  FetchStatus,
  ImportantProductPayload,
  Options,
  ProductUpdate,
  RemoveCartItemsSelectedPayload,
  RemoveCartPayload,
  SelectAllPayload,
  SelectProductPayload,
  SelectStorePayload,
  UpdateCartPayload,
} from './InterfaceZustand';

// Actions are functions which update values in your store. These are static and never change, so they aren't technically "state".
// Organising them into a separate object in our store will allow us to expose them as a single hook to be used in any our component
// without any impact on performance:
interface Actions {
  fetchCartData: () => Promise<void>;
  setSelectAll: (payload: SelectAllPayload, options?: Options) => Promise<void>;
  setSelectStore: (payload: SelectStorePayload, options?: Options) => Promise<void>;
  setSelectProduct: (payload: SelectProductPayload, options?: Options) => Promise<void>;
  setImportantProduct: (payload: ImportantProductPayload, options?: Options) => Promise<void>;
  // TODO: Function updateCartItem use for increase or decrease quantity of cart
  updateCartItem: (payload: UpdateCartPayload, options?: Options) => Promise<void>;
  removeCartItemsSelected: (payload: RemoveCartItemsSelectedPayload) => Promise<void>;
  removeCartItem: (payload: RemoveCartPayload, options?: Options) => Promise<void>;
  removeImportantProducts: (payload: { cartNo: string; skus: string[] }, options?: Options) => Promise<void>;
  setSelectedLists: (payload: any) => void;
  setFirstTimeGetCart: (status: boolean) => void;
  resetFirstTimeGetCart: () => void;
  updateRedeemApplyResult: (payload: any) => void;
  updatePromosRemoved: (payload: string) => void;
  resetPromosRemoved: () => void;
  updateNextUsingPromo: (
    payload: {
      code: string;
      paymentMethod: string;
      paymentMethodName: string;
    } | null,
  ) => void;
}

type InitialCartState = CartStore & { state: FetchStatus };
type CartState = InitialCartState & { actions: Actions };

const INITIAL_STATE: InitialCartState = {
  cartNo: '',
  cartData: [],
  cartDiscounts: [],
  cartFluctuates: [],
  cartErrors: [],
  selectedProducts: [],
  isOpenImportantProducts: false,
  isSelected: false,
  totalDiscount: 0,
  totalItems: 0,
  totalPrice: 0,
  totalItemsSelected: 0,
  totalQuantitySelected: 0,
  totalQuantity: 0,
  totalItemsImportantSelected: 0,
  price: 0,
  state: FETCH_STATUS.IDLE,
  hasDeal: false,
  selectedLists: {},
  isFirstTimeGetCart: true,
  redeemApplyResult: [],
  paymentMethod: '',
  redeemCode: [],
  oldCartItems: [],
  subPrice: 0,
  promosRemoved: '',
  nextUsingPromo: null,
};

const INITIAL_OPTIONS = {
  isReload: true,
  isNotify: true,
};

const useCartStates = create<CartState>()(
  devtools((set, get) => ({
    ...INITIAL_STATE,
    // ⬇️ separate "namespace" for actions
    actions: {
      fetchCartData: async () => {
        try {
          const { isFirstTimeGetCart, promosRemoved } = get();
          const res = await ProductClientV2.getCartBySeller(isFirstTimeGetCart, promosRemoved);
          const getFirstDataCart = getFirst(res);

          if (!getFirstDataCart) {
            set(() => ({ ...INITIAL_STATE, state: FETCH_STATUS.ERROR }));
            return;
          }

          const cartStore: CartStore = convertCartStore(getFirstDataCart);
          const oldCartItemsFormat = cartStore.cartData.map((item) =>
            item.products
              ?.filter((child) => child.isSelected)
              ?.map((product) => ({
                quantity: product.quantityInCart,
                price: product.noneVoucherPrice,
                total: Number(product.noneVoucherPrice) * Number(product.quantityInCart),
                sku: product.skuCode,
                sellerCode: product.sellerCode,
                productTags: product.productTags || [],
                productCode: product.productCode,
                isSelected: product.isSelected,
                storeCode: product.storeCode || '',
              })),
          );

          const newData = {
            ...cartStore,
            state: FETCH_STATUS.IDLE,
            isFirstTimeGetCart: false,
            oldCartItems: oldCartItemsFormat.flat() as IOldCartItem[],
            redeemCode: cartStore?.redeemApplyResult?.map((item: IRedeemApplyResult) => item.code),
          };

          set(() => newData);

          Insider.updateInsiderCartInfo(newData.cartData, INSIDER_CART_SOURCE.CART_STATE);
        } catch (error) {
          set(() => ({
            ...INITIAL_STATE,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      setSelectAll: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            cartNo,
            actions: { fetchCartData },
            cartData,
          } = get();
          const { isReload } = options;
          const { isSelected } = payload;

          set((state) => ({
            ...state,
            isFirstTimeGetCart: true,
          }));

          const body = { isSelected, isAppliedAll: true, cartNo };

          const res = await PUT({ url: CART_API.SELECT_ITEM, body });

          if (!isValidWithoutData(res)) {
            NotifyUtils.error('Không thể chọn nhà bán hàng này, vui lòng thử lại');
          }

          if (isReload) {
            await fetchCartData();
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      setSelectStore: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            cartData,
            cartNo,
            actions: { fetchCartData },
          } = get();
          const { isSelected, skus, sellerGroup } = payload;
          const { isReload } = INITIAL_OPTIONS;

          set((state) => ({
            ...state,
            isFirstTimeGetCart: true,
          }));

          const body = { isSelected, skus, cartNo };

          await PUT({ url: CART_API.SELECT_ITEM, body });

          if (isReload) {
            await fetchCartData();
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      setSelectProduct: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            cartNo,
            actions: { fetchCartData },
          } = get();
          const { name, skuCode, isSelected, quantityInCart, sellerGroup } = payload;
          const { isReload } = options;

          set((state) => ({
            ...state,
            isFirstTimeGetCart: true,
          }));

          const body = {
            isSelected,
            sku: skuCode,
            cartNo,
            quantity: quantityInCart || 0,
            name: name || '',
          };

          const res = await PUT({
            url: CART_API.SELECT_ITEM,
            body,
          });

          if (!isValid(res)) {
            NotifyUtils.error('Chọn sản phẩm không thành công, vui lòng chọn lại');
          }

          if (isReload) {
            await fetchCartData();
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      setImportantProduct: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            actions: { fetchCartData },
          } = get();
          const { cartNo, sku, type, isImportant } = payload;
          const { isReload } = options;

          const body: CartParams & ImportantProductPayload = {
            cartNo,
            sku,
            type,
            isImportant,
            eventSource: mapSourceToEnum({}, window.location.pathname) || '',
            eventScreen: mapScreenToEnum({}, window.location.pathname) || '',
            host: window.location.host || '',
            source: 'thuocsi-web',
          };

          const res = await PUT({ url: CART_API.CART_ITEM, body });

          if (!isValidWithoutData(res)) {
            NotifyUtils.error('Đánh dấu quan trọng sản phẩm thất bại');
            return;
          }

          if (isReload) {
            await fetchCartData();
          }
          NotifyUtils.success('Đánh dấu quan trọng thành công');
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      updateCartItem: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            cartNo,
            actions: { fetchCartData },
          } = get();
          const { newQuantity, product } = payload;
          const { isNotify, isReload } = options;

          const body: CartParams & ProductUpdate = {
            ...product,
            quantity: newQuantity,
            cartNo,
            page: window.location.pathname || 'home',
            searchKey: window.location.search,
            eventSource: product.eventSource || mapSourceToEnum({}, window.location.pathname) || '',
            eventScreen: mapScreenToEnum({}, window.location.pathname) || '',
            host: window.location.host || '',
            source: 'thuocsi-web',
            recommendSKUs: product.recommendSKUs || '',
          };

          const res = await POST({ url: CART_API.CART_ADD, body });

          if (!isValid(res)) {
            NotifyUtils.error('Cập nhật số lượng không thành công');
            return;
          }

          gtag.addToCart({ ...product, quantity: newQuantity || 0 });
          gtag.addToCartInPage({ ...product, quantity: newQuantity || 0 }, window.location.pathname);

          set((state) => ({
            ...state,
            isFirstTimeGetCart: true,
          }));

          if (isReload) {
            await fetchCartData();
          }

          if (isNotify) {
            const productName = capitalizeText(product.name);
            const message =
              (product?.quantity ?? 0) > newQuantity
                ? `Số lượng sản phẩm ${productName} đã được giảm.`
                : `Số lượng sản phẩm ${productName} đã được tăng.`;

            NotifyUtils.success(message);
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      removeCartItemsSelected: async (payload) => {
        try {
          const {
            cartNo,
            actions: { fetchCartData },
          } = get();
          const { skus } = payload;

          const body = {
            cartNo,
            skus,
            source: 'thuocsi-web',
          };

          set((state) => ({
            ...state,
            isFirstTimeGetCart: true,
          }));

          const res = await PUT({ url: CART_API.CART_REMOVE, body });

          await fetchCartData();
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      removeCartItem: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            cartNo,
            actions: { fetchCartData },
          } = get();
          const { product } = payload;
          const { isNotify } = options;

          set((state) => ({
            ...state,
            isFirstTimeGetCart: true,
          }));

          const body: CartParams & ProductUpdate = {
            ...product,
            cartNo,
            page: window.location.pathname || 'home',
            searchKey: window.location.search,
            eventSource: mapSourceToEnum({ isRecommended: false, isSameCategoryProduct: false }, window.location.pathname) || '',
            eventScreen: mapScreenToEnum({ isRecommended: false, isSameCategoryProduct: false }, window.location.pathname) || '',
            host: window.location.host || '',
          };

          const res = await PUT({ url: CART_API.CART_REMOVE, body });

          if (!isValidWithoutData(res)) {
            NotifyUtils.error('Xoá sản phẩm thất bại');
            return;
          }

          if (isNotify) {
            NotifyUtils.success(`Sản phẩm ${capitalizeText(product.name)} đã được xóa ra khỏi giỏ hàng`);
          }

          await fetchCartData();
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      removeImportantProducts: async (payload, options = INITIAL_OPTIONS) => {
        const {
          actions: { fetchCartData },
        } = get();
        const { cartNo, skus } = payload;
        const { isReload } = options;

        const body = {
          cartNo,
          skus,
        };

        const res = await PUT({ url: '/marketplace/order/v2/cart-item/remove-important', body });

        if (isReload) await fetchCartData();
      },
      setSelectedLists: async (data) => {
        set(() => ({
          selectedLists: data,
        }));
      },
      setFirstTimeGetCart: (status: boolean) => {
        set((state) => ({
          ...state,
          isFirstTimeGetCart: status,
        }));
      },
      resetFirstTimeGetCart: () => {
        set((state) => ({
          ...state,
          isFirstTimeGetCart: true,
        }));
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateRedeemApplyResult: (newRedeemApplyResult: any) => {
        set((state) => ({
          ...state,
          redeemApplyResult: newRedeemApplyResult,
        }));
      },
      updatePromosRemoved: (promosRemoved: string) => {
        set((state) => ({
          ...state,
          promosRemoved,
        }));
      },
      resetPromosRemoved: () => {
        set((state) => ({
          ...state,
          promosRemoved: '',
        }));
      },
      updateNextUsingPromo: (nextUsingPromo) => {
        set((state) => ({
          ...state,
          nextUsingPromo,
        }));
      },
    },
  })),
);

export const useCartActions = () => useCartStates((state) => state.actions);

export default useCartStates;

